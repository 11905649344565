// Reused from all-inclusive_apps/packages/all-inclusive-app/helpers/gtm.helper.ts

import { useUserData } from '~/composables/useUserData';
import { Environment } from '~/core';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

type PageViewData = {
  brandcontext?: string;
  category?: string;
  clienttype?: string;
  countrymarket?: string;
  currencycode?: string;
  daysbeforearrival?: number;
  dynamic_datas_ready?: boolean;
  event?: string;
  food_type?: string;
  hotelbrandcode?: string;
  numberofresults?: number;
  orderamount?: number;
  pagename?: string;
  pagetype?: string;
  restaurant_code?: string;
  restaurant_name?: string;
  sub_category_level1?: string;
  sub_category_level2?: string;
  table_booking_id?: string;
  tarsmerchantid?: string;
  tarssourceid?: string;
  uamerchantid?: string;
  uasourceid?: string;
};

type PageViewPayload = {
  dynamic_datas_ready?: boolean;
  event: string;
  page_data: Record<string, unknown>;
  user_data?: Record<string, unknown>;
};

export const gtmEvent = (eventName: string, payload: Record<string, unknown>) => {
  if (process.client) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ event_data: null });

    window.dataLayer.push({
      event: 'GA4event',
      event_data: payload,
      eventName
    });
  }
};

export const gtmUser = () => {
  if (process.client) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ user_data: null });

    window.dataLayer.push({
      event: 'push_dynamic_datas',
      user_data: useUserData()
    });
  }
};

export const pageView = (payload: PageViewData) => {
  const { locale } = useI18n();
  if (process.client) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ page_data: null });

    const envName = useRuntimeConfig().public.appEnv === Environment.Pro ? 'production' : useRuntimeConfig().public.appEnv;

    const genericPayload = {
      env: envName,
      language: locale.value
    };

    const pageViewPayload: PageViewPayload = {
      event: 'push_dynamic_datas',
      page_data: { ...genericPayload, ...payload },
      ...(payload.dynamic_datas_ready ? { dynamic_datas_ready: true } : {})
    };

    window.dataLayer.push(pageViewPayload);
  }
};

export const gtmPageAndUser = (payloadPage: PageViewData) => {
  const { $i18n } = useNuxtApp();
  const locale = $i18n.locale;

  if (process.client) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ page_data: null }, { user_data: null });
    const envName = useRuntimeConfig().public.appEnv === Environment.Pro ? 'production' : useRuntimeConfig().public.appEnv;
    const genericPayload = {
      env: envName,
      language: locale.value
    };

    const pageViewPayload: PageViewPayload = {
      event: 'push_dynamic_datas',
      page_data: { ...genericPayload, ...payloadPage },
      ...(payloadPage.dynamic_datas_ready ? { dynamic_datas_ready: true } : {}),
      user_data: useUserData()
    };

    window.dataLayer.push(pageViewPayload);
  }
};

export const gtmPageName = (routeName: null | string | undefined) => {
  const pageName = routeName?.split('___')[0];

  const resultList = 'results::list';

  let gtmPageName = '';

  switch (pageName) {
    case 'country':
      gtmPageName = 'home';
      break;
    case 'country-city':
      gtmPageName = resultList;
      break;
    case 'country-city-map':
      gtmPageName = resultList;
      break;
    case 'booking-confirmation-restaurantName-bookingId':
      gtmPageName = 'booking::confirmation';
      break;
    case 'page-slug':
      gtmPageName = 'content';
      break;
    case 'point-of-interest-slug':
      gtmPageName = resultList;
      break;
    case 'point-of-interest-slug-map':
      gtmPageName = resultList;
      break;
    case 'restaurant-restaurantCode':
      gtmPageName = 'restaurantdetails';
      break;
    case 'sitemap':
      gtmPageName = 'sitemap';
      break;
    case 'inspiration':
      gtmPageName = 'inspiration';
      break;
    case 'collection-slug':
      gtmPageName = 'content::collection';
      break;
    case 'offer-slug':
      gtmPageName = 'content::offer';
      break;
    case 'article-slug':
      gtmPageName = 'content::article';
      break;

    default:
      gtmPageName = 'error::404';
  }

  return `restaurantsandbars::${gtmPageName}`;
};
