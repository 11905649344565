export function useUserData() {
  const userStore = useUserStore();

  return {
    loyaltycardtypes: userStore?.loyaltyCardTypes,
    newsletteroptin: userStore.individualSubscription ?? '',
    uicity: userStore.userContext.userCity?.toLowerCase() ?? '',
    uicountry: userStore.countryCode?.toLowerCase() ?? '',
    uilogged: userStore.isLogged,
    userId: userStore?.userId ?? '',
    userconsent: true
  };
}
